import Typography from "@mui/material/Typography";
import {List, ListItem} from "@mui/material";
import Box from "@mui/material/Box";
import {Link} from "react-router-dom";

const Info = () => {
  return (
    <>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        INFO
      </Typography>
      <Typography
        variant="body2"
        style={{marginTop: "10px", marginBottom: "10px"}}
      >
        {" "}
        <Box
          component="span"
          fontWeight="bold"
        >
          <Link to="/info-en">INFORMATION IN ENGLISH HERE</Link>
        </Box>
      </Typography>

      <Typography variant="body2">
        Välkommen till Svartmyra utanför Värnamo den 11:e & 12:e juli 2025! Campingområdet kommer att vara öppet mellan
        fredag 11/7 klockan 12 och söndag 13/7 klockan 15. Då finns Punk Illegal-folk på plats och hjälper till med
        frågor och information. Festivalen drar igång fredag 11/7 på Svartmyra. Inträdet där biljetter byts mot armband
        öppnas 12 på fredagen. Absolut inget våld, ingen rasism, ingen sexism, ingen homofobi! Ta hand om varandra!
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        BILJETTER
      </Typography>
      <Typography variant="body2">
        Biljetter säljs bara här:{" "}
        <Box
          component="span"
          fontWeight="bold"
        >
          <a
            href={"https://www.biljettkiosken.se/punkillegal"}
            target="_blank"
            rel="noreferrer"
          >
            biljettkiosken.se/punkillegal
          </a>{" "}
        </Box>
      </Typography>
      <Typography variant="body2">
        Biljetten kostar 450 kronor och gäller för båda dagar. Endagarsbiljetter kommer eventuellt att säljas om det
        finns kvar. Viktigt att notera att Biljettkiosken tar ut en serviceavgift utöver detta pris OBS: Inga biljetter
        kommer att säljas på plats!
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        MAT & DRYCK
      </Typography>
      <Typography variant="body2">
        Under festivalen kommer Punk Illegal att servera en hel del god mat och dryck. Du kan välja mellan olika
        veganska alternativ. Fråga vid allergi. Det kommer också att finnas en food truck som serverar vegansk mat. Även
        frukost kommer att säljas. Det kommer att finnas gott om dryck till försäljning.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        SOPHANTERING
      </Typography>
      <Typography variant="body2">
        Sopstationer finns utsatta på strategiska ställen och ska naturligtvis användas flitigt för sortering av skräp
        och skrot. En självstädande fest är vad vi eftersträvar och vad vi alla vill ha. Slarva inte med att slänga
        fimpar och småskräp på marken. Vi har upptäckt att fåglarna bygger bo av era nikotinrester.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        SPRAYBURKAR/TAGPENNOR:
      </Typography>
      <Typography variant="body2">Vi uppskattar att alla tuschpennor, sprayburkar och annat lämnas hemma.</Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        CAMPINGEN
      </Typography>
      <Typography variant="body2">
        Då vi inte har plats att husera alla som kommer är det camping som gäller. Denna är gratis och sker på utsatt
        område. Vi ber alla att hålla sig inom detta. Ni kan tälta, bo i husvagn/husbil, eller sova under bar himmel.
        Enkla toaletter & rinnande vatten kommer finnas på plats. I närheten finns även badplatser, dock bilavstånd om
        du inte är hurtig. På campingen är det viktigt att alla respekterar varandra. Med tanke på omgivning får under
        inga omständigheter eldar tändas. Respektera detta då vi befinner oss ute på landet med ängar och lador. Detta
        gäller även utanför festivalens område. Campingkök/Triangakök är tillåtna men var försiktig när du använder dem!
        Använd dassen och pissrännan då boende på gården även ska kunna bo där efter festens slut. Håll dem rena så gott
        det går. Kasta skräp i återvinning eller papperskorgarna och städa runt din tältplats innan du åker hem!
        Respektera dina campinggrannar och var snäll! Det är också folk som bor på området året runt, så tänk på att de
        vill ha det snyggt även efter festivalen! Parkering sker på utsatt plats inom området. Följ parkering- och
        campingansvarigas direktiv. Det är förbjudet att bo på campingen utan biljett.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        ANDRA PLATSER FÖR ÖVERNATTNING
      </Typography>
      <Typography variant="body2">
        Vi rekommenderar att ni campar då det är bilavstånd till campingstugor och hotell och då kollektivtrafiken inte
        stannar nära festivalområdet. Vill ni ha tips om boende ändå finns exempelvis Värnamo camping:
        <Box
          component="span"
          fontWeight="bold"
        >
          <a
            href={"https://camping.se/sv/campingar/2677/Varnamo-Camping-Prostsjon"}
            target="_blank"
            rel="noreferrer"
          >
            camping.se/sv/campingar/2677/Varnamo-Camping-Prostsjon
          </a>{" "}
        </Box>
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        BETALNING PÅ FESTIVALEN
      </Typography>
      <Typography variant="body2">
        Swish och svenska kontanter är enda betalningsmedel. Närmaste bankomat ligger långt bort så se till att ta ut
        kontanter innan du åker.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        MERCH/SKIVOR
      </Typography>
      <Typography variant="body2">
        De flesta band tar med sin egen merch och säljer den i bandmerchen. Det kommer även finnas andra distros på
        festivalområdet.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        HUNDAR OCH BARN
      </Typography>
      <Typography variant="body2">
        Hundar får tas med men måste alltid hållas kopplade då det finns andra djur på gården som inte uppskattar
        jagande hundar. Döda djur, i vilket skick de än är eller hur de än är förpackade, lämnas naturligtvis hemma. Vi
        ser ogärna hundar framför scen på grund av hög musik. Barn upp till 13 kommer bara in med målsman (gratis), men
        se till att de har hörselskydd! Ungdomar under 18 kommer att få ett speciellt festivalarmband men betalar
        fullpris.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        BIL
      </Typography>
      <Typography variant="body2">
        Det är allra lättast att ta sig till Svartmyra med bil. Försök att samåka.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        FLYG
      </Typography>
      <Typography variant="body2">
        De närmaste flygplatserna ligger i Växjö och Jönköping men de flyger till få destinationer. Annars ligger
        Köpenhamn och Göteborgs flygplatser cirka 3 timmar bort med buss/tåg.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        TÅG
      </Typography>
      <Typography variant="body2">Den närmaste tågstationen ligger i Värnamo.</Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        SHUTTLEBUS
      </Typography>
      <Typography variant="body2">
        Punk illegal anordnar en shuttlebus från Värnamo till festivalen. Dock kommer den att ta begränsat med folk,
        inte gå så ofta och bara vara igång på fredagen och söndagen. Enkel väg kostar 50 kronor och biljett behöver
        bokas i förväg.
      </Typography>
      <Typography
        variant="h5"
        component="div"
        style={{marginTop: "15px", marginBottom: "10px"}}
      >
        TILLGÄNGLIGHET
      </Typography>
      <Typography variant="body2">
        <List
          sx={{listStyleType: "disc", pl: 4}}
          dense
        >
          <ListItem sx={{display: "list-item"}}>
            <Box
              component="span"
              fontWeight="bold"
            >
              Ljud/ljus:
            </Box>{" "}
            Hörslingor finns ej på scen.
          </ListItem>
          <ListItem sx={{display: "list-item"}}>
            {" "}
            <Box
              component="span"
              fontWeight="bold"
            >
              Ledsagare:
            </Box>{" "}
            Ledsagare och vårdare går in kostnadsfritt vid uppvisande av ledsagarpass. Ledsagarhundar likaså men ej
            under konsert.
          </ListItem>
          <ListItem sx={{display: "list-item"}}>
            {" "}
            <Box
              component="span"
              fontWeight="bold"
            >
              HKP-toalett:
            </Box>{" "}
            Finns i form av en HKP-bajamaja.
          </ListItem>
          <ListItem sx={{display: "list-item"}}>
            <Box
              component="span"
              fontWeight="bold"
            >
              Scener och lokal:
            </Box>{" "}
            En utomhusscen finns, och en inomhusarea där också dj:s och distron kommer hålla till.
          </ListItem>
          <ListItem sx={{display: "list-item"}}>
            <Box
              component="span"
              fontWeight="bold"
            >
              Området:
            </Box>{" "}
            Det mesta av området består av gräs och en ojämn mark som kan vara svårframkomlig. Bra att ha någon med som
            kan hjälpa till att skjuta på om man sitter i rullstol.
          </ListItem>
        </List>
      </Typography>
    </>
  );
};

export default Info;
